<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item label="放款失败日期">
              <a-range-picker v-model="queryParam.time" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'订单编号'">
              <a-input v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>


          <a-col :md="8" :sm="12">
            <a-form-item :label="'系统身份证号'">
              <a-input placeholder="" v-model="queryParam.curp"></a-input>
            </a-form-item>
          </a-col>



          <a-col :md="6" :sm="8">
            <a-form-item :label="'处理状态'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.status">

                <a-select-option value="0">
                  未处理
                </a-select-option>
                <a-select-option value="1">
                  已处理
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset')
                }}</a-button>

            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table ref="table" rowKey="id" size="middle" :columns="columns" :dataSource="dataSource" :pagination="false"
        :loading="loading" :scroll="{ x: 1000 }" bordered @change="handleTableChange">
        <span slot="status" slot-scope="text,record">{{ record.status == '0' ? '未处理' : record.status == 1 ? '已处理' : ''
          }}</span>
        <span slot="updateTime" slot-scope="text,record"> {{ transformTime(record.updateTime) }}</span>
        <span slot="action" slot-scope="text, record">
          <a @click="getCustomerDetailMethod(record)">编辑</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination v-if="total" :page-num="queryParam.pageNum" :page-size="queryParam.pageSize" :total="total"
          @change="handleTableChange" />
      </div>
    </div>
    <a-modal v-model="visible" title="详情" @ok="handleOk" @cancel="editCancel">
      <div class="zlBox1">
        <div class="formInput">
            <div class="Item">
              <div class="label">系统身份证号：</div>
              <div class="text">{{ recordObj.originCurp }}</div>
            </div>
            <div class="Item">
              <div class="label">修改：</div>
              <a-input v-model="modifiedCurp"></a-input>
            </div>
            <div class="Item">
              <div class="label">状态：</div>
              <a-select default-value="" style="width: 120px" v-model="modifiedstatus">

                <a-select-option value="0">
                  未处理
                </a-select-option>
                <a-select-option value="1">
                  已处理
                </a-select-option>
              </a-select>
            </div>
          </div>
        <div class="fdsx">
          <a-icon type="plus-circle"  @click.prevent="scaleUp" style="font-size: 20px;" />
          <a-icon type="minus-circle"  @click.prevent="scaleDown"  style="font-size: 20px;"/>
        </div>
        <div class="image-container" @wheel="handleWheel">
          <img :src="imgUrl" :style="imageStyle" alt="Zoomable Image"  >
        </div>
        
      </div>
      <div class="zlBox2">
        <div class="ItemTitle"></div>
        <div class="zlboxCon">
          
          <div class="imageBox" v-if="imageInfos">
            <img :src="item.imgUrl" @click="imgUrl=item.imgUrl" alt="" v-for="(item, index) in imageInfos" :key="index">
          </div>
        </div>

      </div>

    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { orderLoanFailList, updateCurp, orderLoanFailImgInfos } from '@/webpublicapi/order'
// import {imageMixin} from "@/mixins/image-mixin.js"
// import imageMixin from '../../../mixins/image-mixin'
import moment from 'moment'
export default {
  name: 'FiledOrderList',
  data() {
    return {
      imgUrl:"",
      visible: false,
      // 分页参数
      dataSource: [],
      total: 0,
      // 查询条件
      queryParam: {
        time: [moment().startOf('day').add(-1, 'week').format('YYYY-MM-DD'), moment().startOf('day').format('YYYY-MM-DD')],
        mobileNo: "",
        orderNo: "",
        curp: "",
        status: "",
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      imageInfos: [],
      recordObj: {},
      modifiedCurp: "",
      modifiedstatus: "",
      scale: 1,
        minScale: 1,
        maxScale: 5,

    }
  },
  // mixins: [imageMixin],
  computed: {
    imageStyle() {
        return {
          transform: `scale(${this.scale})`,
          transition: 'transform 0.1s ease-in-out',
        };
      },
    columns() {
      return [
        {
          title: "订单编号",
          align: 'center',
          width: '150px',
          dataIndex: 'orderNo'
        },
        {
          title: "手机号",
          align: 'center',
          width: '110px',
          dataIndex: 'mobileNo'
        },
        {
          title: "系统身份证号",
          align: 'center',
          width: '130px',
          dataIndex: 'originCurp'
        },
        {
          title: "图片身份证号",
          align: 'center',
          width: '130px',
          dataIndex: 'modifiedCurp'
        },
        {
          title: "处理状态",
          align: 'center',
          width: '100px',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        ,
        {
          title: "处理时间",
          align: 'center',
          width: '100px',
          dataIndex: 'updateTime',
          scopedSlots: { customRender: 'updateTime' }
        },

        {
          title: "处理人",
          align: 'center',
          width: '110px',
          dataIndex: 'updateBy'
        },
        {
          title: "操作",
          dataIndex: 'action',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created() {
    // this.loadData(1)
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    editCancel() {
      this.visible = false
      this.recordObj = {}
      this.modifiedCurp = ""
      this.modifiedstatus = ""
    },
    handleOk() {
      this.loading = true
      updateCurp({
        id: this.recordObj.id,
        modifiedCurp: this.modifiedCurp,
        status: this.modifiedstatus,
      }).then(res => {
        if (res.code === 1000) {
          this.$message.success("修改成功")
          this.visible = false
          this.recordObj = {}
          this.modifiedCurp = ""
          this.modifiedstatus = ""
          this.loadData()
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })

    },
    getCustomerDetailMethod(record) {
      this.loading = true
      this.recordObj = record
      orderLoanFailImgInfos({ id: record.id }).then(res => {
        if (res.code === 1000) {
          this.imageInfos = res.data.imageInfos
          this.imgUrl=this.imageInfos[0].imgUrl
          this.visible = true
        } else {

          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        // this.form.pageNum = 1
        this.queryParam.pageNum = 1
      }
      this.loading = true
      let obj = {}
      obj.startDate = this.transformTime(this.queryParam.time[0]) ? this.transformTime(this.queryParam.time[0]) : ''
      obj.endDate = this.transformTime(this.queryParam.time[1]) ? this.transformTime(this.queryParam.time[1]) : ''
      obj.mobileNo = this.queryParam.mobileNo
      obj.orderNo = this.queryParam.orderNo
      obj.curp = this.queryParam.curp
      obj.status = this.queryParam.status
      obj.pageNum = this.queryParam.pageNum
      obj.pageSize = this.queryParam.pageSize
      orderLoanFailList(obj).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },

    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum;
      this.queryParam.pageSize = pageSize;
      this.loadData();
    },
    handleWheel(event) {
      event.preventDefault();
      const delta = event.wheelDelta ? event.wheelDelta : -event.deltaY;
      if (delta > 0) {
        this.scaleUp();
      } else {
        this.scaleDown();
      }
    },
    scaleUp() {
      if (this.scale < this.maxScale) {
        this.scale += 0.3; // 根据需求设置每次放大的比例
      }
    },
    scaleDown() {
      if (this.scale > this.minScale) {
        this.scale -= 0.3; // 根据需求设置每次缩小的比例
      }
    },
    searchReset() {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
      that.queryParam.time = []
      that.queryParam.curp = ''
      that.queryParam.status = '';
      that.loadData(this.queryParam.pageNum)
    }

  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";

// .ant-modal-content{
//   box-sizing: border-box;
//   width: 100vw;
//   height: 100vh;
// }
.image-container {
  overflow: hidden;
  height: 100%; /* 设置图片容器的高度 */
  width:  100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow-y: scroll;
}
.zlBox1 img {
    // max-width: 100%;
    // max-height: 100%;
    height: 100%;
    pointer-events:none;
    cursor: zoom-in; /* Optional zoom-in cursor */
  }
  ::selection {
    background: none !important;
  }
.fdsx{
  width: 100px;
  height: 150px;
  position: absolute;
  left: 50%;
  bottom: -60px;
  transform: translate(-50%,0);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 99999999999;


}

/deep/ .ant-modal {
  max-width: 100% !important;
  top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}

/deep/ .ant-modal-content {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

/deep/ .ant-modal-body {
  box-sizing: border-box;
  height: calc(100vh - 120px);
  max-height: 100vh;
  padding-top: 0px;
}

.ItemTitle {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 10px;
  width: 100%;
  background-color: #eee;
}

.imageBox {
  flex: 1;
  padding: 10px;
  display: flex;
  overflow-x: scroll;

 
}

.imageBox img {
  width: 120px;
  height: 160px;
  margin-right: 20px;
  flex-shrink: 0;
}

.zlBox1 {  
  flex: 1;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 30px;

}

.zlBox2 {
  width: 100%;
  min-height: 200px;
  border: 1px solid #aaa;
  padding: 14px;
  padding-top: 10px;
  position: relative;
  font-size: 15px;


}

.zlBox2 .zlboxCon {
  width: 100%;
  display: flex;
}

.formInput {
  position: absolute;
  left: 10px;
  bottom: 200px;
  width: 300px;
  margin-right: 20px;
}

.formInput .Item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.label {
  min-width: 50px;
  white-space: nowrap;
  text-align: center;
  font-size: 16px;
}

.Item .ant-input {
  width: 240px;
  padding-left: 10px;
}

/deep/ .ant-select-selection--single {
  width: 240px;
}

#rcDialogTitle1 {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
}

/deep/ .ant-modal-title {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
}
/deep/ .ant-modal-body{
  display: flex;
  flex-direction: column;
}
.image-container {
  --antd-wave-shadow-color: transparent !important;
}
img{
  --antd-wave-shadow-color: transparent !important;
}
</style>

import { axios } from '@/utils/request'

// 应用管理
export function getAppJsonList (parameter = {}) {
  return axios({
    url: '/operateJson/list',
    method: 'post',
    data: parameter
  })
}

export function detailJsonContent (parameter = {}) {
  return axios({
    url: '/operateJson/jsonDetail',
    method: 'post',
    data: parameter
  })
}


import {axios} from "@/utils/request";

export function getTaskLimitConfig (parameter = {}) {
  return axios({
    url: '/taskLimitConfig/getTaskLimitConfig',
    method: 'post',
    data: parameter
  })
}

export function getTaskLimitConfigByNormalConfigId (parameter = {}) {
  return axios({
    url: '/taskLimitConfig/getTaskLimitConfigByNormalConfigId',
    method: 'post',
    data: parameter
  })
}

export function saveAndUpdateTaskLimitConfig (parameter = {}) {
  return axios({
    url: '/taskLimitConfig/saveAndUpdateTaskLimitConfig',
    method: 'post',
    data: parameter
  })
}


export function deleteTaskLimitConfig (parameter = {}) {
  return axios({
    url: '/taskLimitConfig/deleteTaskLimitConfig',
    method: 'post',
    data: parameter
  })
}
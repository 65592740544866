<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="7" :sm="8">
            <a-form-item label="应用名称" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入应用名称" v-model="queryParam.appName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="操作类型">
                <a-select  v-model="cardAttr" placeholder='请选择操作类型' style='width: 150px'>
                  <a-select-option :value='item.id' v-for="(item,index) in cardType"  :key='index'>
                    {{item.name}}
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8" >
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <div slot="action" slot-scope="text, record">
          <span v-show="cardAttr==1" >
            <a-divider type="vertical"/>
            <a @click="chooseConvertFile(record)">文件转换上传</a>
            <a-divider type="vertical"/>
            <a @click="detailContent(record,'1')">文件转换详情</a>
         </span>
          <span v-show="cardAttr==2" >
            <a-divider type="vertical"/>
            <a @click="chooseJsonFile(record,'2')">打点上传</a>
            <a-divider type="vertical"/>
            <a @click="detailContent(record,'2')">打点详情</a>
         </span>
          <span   v-show="cardAttr==3" >
            <a-divider type="vertical"/>
            <a @click="chooseJsonFile(record,'3')">数据字典上传</a>
            <a-divider type="vertical"/>
            <a @click="detailContent(record,'3')">数据字典详情</a>
         </span>
          <span   v-show="cardAttr==4" >
            <a-divider type="vertical"/>
            <a @click="chooseJsonFile(record,'4')">数据校验上传</a>
            <a-divider type="vertical"/>
            <a @click="detailContent(record,'4')">数据校验详情</a>
         </span>
          <span   v-show="cardAttr==5" >
            <a-divider type="vertical"/>
            <a @click="chooseJsonFile(record,'5')">AppInfo配置上传</a>
            <a-divider type="vertical"/>
            <a @click="detailContent(record,'5')">AppInfo配置详情</a>
         </span>
        </div>
      </a-table>

      <div class="table-page-wrapper">
        <a-pagination size="small" v-model="ipagination.pageNum" :pageSizeOptions="ipagination.pageSizeOptions"
                      :pageSize="ipagination.pageSize"
                      :total="ipagination.total"
                      :show-total="total => `共 ${total} 条`"
                      show-size-changer show-quick-jumper @change="handlePageChange" @showSizeChange="handlePageChange"/>
      </div>
      <a-modal  v-model="visdialog" title="请选择json转换文件" @ok="upLoad" width="800px">
        <div class="btn_select">
          <div>
            <input type="file" name="file" accept="application/json" @change="changeJson"   ref="fileadd">
          </div>
        </div>
      </a-modal>

      <a-modal  v-model="jsonVisdialog" title="请选择json文件" @ok="uploadJsonContent" width="800px">
        <div class="btn_select">
          <div>
            <input type="file" name="file" accept="application/json" @change="changeJsonFile"   ref="jsonfileadd">
          </div>
        </div>
      </a-modal>

      <a-modal  v-model="detailDialog" :title="detailFileName"  width="800px">
        <div class="btn_select">
          <div>
            <json-viewer :value="jsonData" :expand-depth="5" copyable boxed sort></json-viewer>
          </div>
        </div>
        <template slot="footer">
          <a-button @click="handleCancel">关闭</a-button>
        </template>
      </a-modal>
      <a-modal  v-model="compareDialog" title="比较"  @ok="saveJsonFile" width="1300px">
        <div class="btn_select">
          <div>
            <section>
              <div>
                <code-diff
                  :new-string="newStr"
                  :old-string="oldStr"
                  outputFormat="side-by-side"
                  :context="100"
                >
                </code-diff>
              </div>
            </section>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {
  detailJsonContent,
  getAppJsonList
} from '@/webpublicapi/appJson'
import Vue from 'vue'
import JsonViewer from "vue-json-viewer";
// Vue.use(JsonViewer)
import CodeDiff from "vue-code-diff";
import { axios } from '@/utils/request'
import 'vue-json-viewer/style.css';


const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
};
export default {
  name: 'AppJsonList',
  components: {
    JsonViewer,
    CodeDiff
  },
  data () {
    return {
      cardType:[{'id':1,name:'文件转换'},{'id':2,name:'打点'},{'id':3,name:'数据字典'},{'id':4,name:'数据校验'},{'id':5,name:'AppInfo配置'}],
      cardAttr:1,
      oldStr: "",
      newStr: "",
      jsonData:'',
      detailFileName:'',
      detailJsonContent:"",
      oldJsonFileName:"",
      oldJsonContent:"",
      newJsonContent:"",
      filex:"",
      appSsid:"",
      appName:"",
      showUrl:"",
      jsonType:"",
      visdialog:false,
      jsonVisdialog:false,
      detailDialog:false,
      compareDialog:false,
      I18N_KEY,
      visible: false,
      // 分页参数
      ipagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        appName: '',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '应用名称',
          align: 'left',
          width: '100px',
          dataIndex: 'appName'
        },
        {
          title: '盘号',
          align: 'left',
          width: '100px',
          dataIndex: 'appSsid'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadData(1)
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    changeJson() {
      this.filex = this.$refs.fileadd.files[0]
    },
    changeJsonFile() {
      this.filex = this.$refs.jsonfileadd.files[0]
    },
    handleCancel() {
      this.detailDialog=false
      this.jsonData = ''
      this.detailFileName=''
    },
    upLoad() {
      // this.file  上传上来的文件
      let jsonFile = this.filex;//获取到上传的图片
      let formData = new FormData();//通过formdata上传
      formData.append('file', jsonFile)
      formData.append('appSsid', this.appSsid)
      formData.append('appName', this.appName)
      formData.append('showUrl', this.showUrl)
      this.axios({
        header:{
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url: "/operateJson/uploadConvertJsonFile",
        data: formData,
        core: true
      })
        .then((res) => {
            if(res.code===1000){
              this.visdialog=false
              this.$refs.fileadd.value = ''; // 清空file文件内容
              this.newJsonContent = res.data.newJsonContent
              this.oldJsonContent = res.data.oldJsonContent
              this.oldJsonFileName = res.data.oldJsonFileName
              //比较插件
              this.compareDialog= true
              if (this.oldJsonContent == "") {
                this.oldStr="";
              } else {
                let oldJSON = JSON.parse(this.oldJsonContent)
                this.oldStr=JSON.stringify(oldJSON, null, 4);
              }
              let newJSON =JSON.parse(this.newJsonContent)
              this.newStr=JSON.stringify(newJSON, null, 4);
            }else{
              this.$refs.fileadd.value = ''; // 清空file文件内容
              this.$message.error(res.msg)
            }
        })
        .catch((error) => {
          console.log(error);
        })

    },
    uploadJsonContent() {
      let jsonFile = this.filex;//获取到上传的图片
      let formData = new FormData();//通过formdata上传
      formData.append('file', jsonFile)
      formData.append('appSsid', this.appSsid)
      formData.append('jsonType', this.jsonType)
      this.axios({
        header:{
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url: "/operateJson/uploadJsonFile",
        data: formData,
        core: true
      })
        .then((res) => {
          if(res.code===1000){
            this.jsonVisdialog=false
            this.$refs.jsonfileadd.value = ''; // 清空file文件内容
            this.$message.success(this.$t('success'))
          }else{
            this.$refs.jsonfileadd.value = ''; // 清空file文件内容
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error);
        })

    },

    saveJsonFile() {
      let formData = new FormData();//通过formdata上传
      formData.append('fileName', this.oldJsonFileName)
      formData.append('newJsonContent', this.newJsonContent)
      formData.append('oldJsonContent', this.oldJsonContent)
      this.axios({
        method: "post",
        url: '/operateJson/saveConvertJsonFile',
        data: formData,
      })
        .then((res) => {
          if(res.code===1000){
            this.compareDialog = false
            this.$message.success(this.$t('success'))
          }else{
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error);
        })
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAppJsonList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    chooseConvertFile(e) {
      this.visdialog=true
      this.appSsid=e.appSsid
      this.appName=e.appName
      this.showUrl=e.showUrl
    },
    chooseJsonFile(e,jsonType) {
      this.jsonVisdialog=true
      this.appSsid=e.appSsid
      this.jsonType = jsonType
    },
    handlePageChange(pageNum, pageSize) {
      this.ipagination.pageNum = pageNum
      this.ipagination.pageSize = pageSize
      this.loadData()
    },
    getQueryParams () {
      const data = {
        ...this.queryParam,
      }
      var param = Object.assign({}, data)
      param.pageNum = this.ipagination.pageNum
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    createJsonParams (row,jsonType) {
      let param = {}
      if (jsonType == '1') {
        param.fileName = row.showUrl
      } else if (jsonType == '2'){
        param.fileName = row.appSsid
      }else if(jsonType == '3'){
        param.fileName = row.appSsid
      } else if(jsonType == '4'){
        param.fileName = row.appSsid
      } else if(jsonType == '5'){
        param.fileName = row.appSsid
      }
      param.jsonType = jsonType
      return filterObj(param)
    },

    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appName = ''
      this.loadData();
    },
    detailContent(row,jsonType) {
      var params = this.createJsonParams(row,jsonType)// 查询条件
      detailJsonContent(Object.assign({}, params)).then(res => {
        if(res.code===1000){
          this.detailDialog=true
          let data = res.data.jsonContent
          let title = res.data.fileName
          // console.log("title："+title +"data:" + data)
          if(typeof data !== 'undefined' && data != null && data !== ''){
            //把json 字符串转换成对象 再显示
            this.jsonData = JSON.parse(data);
          }
          if (typeof title !== 'undefined' && title != null && title !== '') {
            this.detailFileName = title;
          }
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
//json详情滚动样式
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-icon-close:before {
  color: black;
}
::v-deep .jv-container .jv-code.boxed {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
}
::v-deep .jv-container .jv-code.boxed::-webkit-scrollbar {
  display: none;
}
</style>

<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item label="日期" >
              <a-range-picker
                v-model="queryParam.time"
                format="YYYY-MM-DD"
                style="width: 230px;"/>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item label="逾期天数">
              <a-input :placeholder="'样例：2-3 或 2'" v-model="queryParam.overdueDays"  style='width: 170px'></a-input>
            </a-form-item>
          </a-col>
          <a-col :md='6' sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        :columns='columns'
        :dataSource='dataSourceTotal'
        :pagination='false'
        bordered>
      </a-table>
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :showHeader="false"
        bordered
        :rowClassName="tableRowClass">
      </a-table>

      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>

    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import moment from 'moment'
import { getRepaymentReportList } from '@/webpublicapi/repaymentReport'


const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  FOLLOW: 'collectorTotalFollow.'
}


export default{
  name: 'oaWorkplace',
  components: {
  },
  data() {
    return {
       columns :
         [{
           title: '日期',
           dataIndex: 'statisticDate',
           width:200
         }, {
           title: '实际还款金额',
           dataIndex: 'amount',
           width:200
         }, {
           title: '实际正常还款金额',
           dataIndex: 'normalAmount',
           width:200
         }, {
           title: '实际展期金额',
           dataIndex: 'extensionAmount',
           width:200
         }, {
           title: '实际还款滞纳金',
           dataIndex: 'lateFee',
           width:200
         }, {
           title: '减免金额',
           dataIndex: 'reductionAmount',
           width:200
         }, {
           title: '优惠券减免金额',
           dataIndex: 'couponReductionAmount',
           width:200
         }, {
           title: '逾期天数',
           dataIndex: 'overdueDays',
         }],
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      dataSourceTotal: [],
      // 查询条件
      queryParam: {
        time: [],
        overdueDays: '',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
    }
  },
  created() {
    this.getTime();
    this.loadData(1)
  },
  methods: {
    getTime() {
      this.queryParam.time = [];
      var nowDate = new Date();
      this.queryParam.time[0]=moment(nowDate.setDate(nowDate.getDate() - 15));
      this.queryParam.time[1]=moment(new Date());
    },
    searchQuery() {
      this.loadData(1)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    loadData(arg) {
      var params = this.getQueryParams()// 查询条件
      if (!params.beginTime) {
        this.$message.error("日期不能为空");
        return;
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true

      getRepaymentReportList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
          this.dataSourceTotal = res.data.summary
        } else {
          this.total = 0

          this.dataSource = []
          this.dataSourceTotal = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      var param = this.queryParam;
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      param.beginTime = (param.time && param.time.length) ? this.transformTime(param.time[0]) : undefined;
      param.endTime = (param.time && param.time.length) ? this.transformTime(param.time[1]) : undefined;

      return filterObj(param)
    },
    transformTime (time) {
      return time.format('YYYY-MM-DD')
    },
    // 重置搜索框的内容
    searchReset() {
      this.getTime();
      this.queryParam.overdueDays = ''
    },
    tableRowClass(record) {
      if(record.statisticDate=='合计') {
        return "rowClass";
      } else {
        return "";
      }
    },
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";

::v-deep .rowClass {
  //color: blue;
  font-weight:bold;
}

</style>

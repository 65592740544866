import { axios } from '@/utils/request'

// 应用管理
export function getRepaymentReportList (parameter = {}) {
  return axios({
    url: '/repaymentReport/list',
    method: 'post',
    data: parameter
  })
}

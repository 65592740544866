<template>
  <div>
    <div style="padding: 15px;">
      <a-button type="primary">
        <a href="javascript:;" @click="createGroup(null,false)">新增</a>
      </a-button>
    </div>
    <a-table
      :dataSource="list"
      :pagination="false"
      :loading="loading"
      rowKey="orderInfoId"
      bordered
      @change="handleTableChange">
      <a-table-column
        title="催收级别"
        dataIndex="normalConfigId"
        key="normalConfigId"
        align="center"
      />
      <a-table-column
        title="是否开启"
        dataIndex="isEnable"
        key="isEnable"
        align="center"
      />
      <a-table-column
        title="任务限制数量"
        dataIndex="limitNum"
        key="limitNum"
        align="center"
      />
      <a-table-column
        title="操作"
        dataIndex="action"
        key="action"
        align="center"
      >
        <template slot-scope="text, record">
              <span>
                  <a href="javascript:;" @click="deleteTaskLimitConfig(record.normalConfigId)">删除</a>
              </span>
              <span>
                  <a href="javascript:;" @click="createGroup(record,true)">修改</a>
              </span>
        </template>
      </a-table-column>
    </a-table>
    <add-or-update-modal ref="addOrUpdateModal" @refresh="handleTableChange"/>
  </div>

</template>
<script>
  import AddOrUpdateModal from "@/views/collection/modules/AddOrUpdateModal";
  import {getTaskLimitConfig,deleteTaskLimitConfig} from '@/api/taskLimitConfig'

  export default {
    components:{AddOrUpdateModal},
    data () {
      return {
        loading: false,
        form: {
          orderNo: undefined,
        },
        list: [],
      }
    },
    computed: {

    },
    created () {
      this.search();
    },
    methods: {
      createGroup(normalConfigId,type){
        this.$refs.addOrUpdateModal.show(normalConfigId,type);
      },
      handleTableChange () {
        this.search();
      },
      deleteTaskLimitConfig(normalConfigId){
        this.$confirm({
          title: '删除当前配置',
          content: '删除当前配置',
          onOk: () => {
              this.loading = true;
              deleteTaskLimitConfig({ normalConfigId: normalConfigId }).then(res => {
              this.search();
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.message);
            });
          }
        });
      },

      search () {
        this.loading = true;
        getTaskLimitConfig(this.form).then(res => {
          this.loading = false;
          this.list = res && res.data && res.data.list || [];
          this.total = res && res.data && res.data.total;
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      },
    }
  }
</script>
